import classNames from 'classnames';
import * as React from 'react';
import { useContext } from 'react';
import { PageContext } from './page-context';

interface IFooterProps {
  ref: React.Ref<HTMLElement>;
}

const Footer: React.ForwardRefExoticComponent<IFooterProps> = React.forwardRef((props, ref) => {
  const page = useContext(PageContext);

  const isDashboardPage = () => window.location.pathname.startsWith('/dashboard/');
  let dashboardFooterClass = page.usesDarkMode ? 'u-bg-dash-dark' : 'u-bg-dash-light';

  return (
    <footer id="site-footer" className={classNames(['c-site-footer', { 'c-color--invert': page.usesDarkMode, 'u-bg-dash-dark': isDashboardPage() && page.usesDarkMode, 'u-bg-dash-light': isDashboardPage() && !page.usesDarkMode }])} ref={ref}>
      <div className="o-container-fluid o-container--full">
        <div className="c-site-footer__wrapper">
          <div className={classNames(['c-site-logo', { 'c-site-logo--invert': page.usesDarkMode }, 'c-site-logo--basic'])}></div>

          <div className="c-site-footer__info">
            <p>©{new Date().getFullYear()} WPNotify All Rights Reserved.</p>
            <ul>
              {/* <li><a href="https://www.convertiv.com/privacy-policy/">Privacy Policy</a></li> */}
              <li><a href="https://www.convertiv.com/privacy-policy/">Terms & Conditions</a></li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
});

export default Footer;
