import { types, getSnapshot, applySnapshot, ModelSnapshotType } from 'mobx-state-tree';

import api from 'api';
import Tokens from './tokens';
import Errors from './errors';
import Notifications from './notifications';
import config from 'config';
import { UserRoleTypes } from 'api/endpoints/users';
import Cookies from 'js-cookie';
import * as Sentry from "@sentry/react";

export enum LoginStatus {
  LoggedOut = 'logged-out',
  LoggingIn = 'logging-in',
  LoggedIn = 'logged-in',
  LoggingOut = 'logging-out',
}

let initialSnapshot: ModelSnapshotType<any>;

export const RootStore = types
  .model('RootStore', {
    loginStatus: types.optional(
      types.enumeration<LoginStatus>('loginStatus', [
        LoginStatus.LoggedOut,
        LoginStatus.LoggingIn,
        LoginStatus.LoggedIn,
        LoginStatus.LoggingOut,
      ]),
      LoginStatus.LoggingIn
    ),
    userRole: types.maybe(
      types.enumeration<UserRoleTypes>('userRole', [
        UserRoleTypes.User,
        UserRoleTypes.Admin,
      ])
    ),
    tokens: Tokens,
    errors: Errors,
    notifications: Notifications,
  })
  .views((self) => ({
    get isLoggedIn() {
      return self.loginStatus === LoginStatus.LoggedIn;
    },
    get isLoggedOut() {
      return self.loginStatus === LoginStatus.LoggedOut;
    },
    get isLoggingIn() {
      return self.loginStatus === LoginStatus.LoggingIn;
    },
    get isLoggingOut() {
      return self.loginStatus === LoginStatus.LoggingOut;
    },
    get isUser() {
      return self.userRole === UserRoleTypes.User;
    },
    get isSuperAdmin() {
      return self.userRole === UserRoleTypes.Admin;
    },
  }))
  .actions((self) => ({
    afterCreate: () => {
      initialSnapshot = getSnapshot(self);
    },
    resetStore: () => {
      applySnapshot(self, initialSnapshot);
    },
    setLoginStatus(status: LoginStatus) {
      self.loginStatus = status;
    },
    setUserRole(role: UserRoleTypes) {
      self.userRole = role;
    },
  }))
  .actions((self) => ({
    init: () => {
      self.setLoginStatus(LoginStatus.LoggingIn);
      self.tokens.restoreTokens();
      if (self.tokens.hasToken) {
        self.setLoginStatus(LoginStatus.LoggedIn);
      } else {
        self.setLoginStatus(LoginStatus.LoggedOut);
      }
    },
    login: async ( data: { username: string, password: string }) => {
      self.setLoginStatus(LoginStatus.LoggingIn);
      try {
        const response = await api.auth.getToken({
          client_id: config.API_CLIENT_ID,
          client_secret: config.API_CLIENT_SECRET,
          grant_type: 'password',
          ...data
          }).fetch();

        self.tokens?.setAccessToken(response.access_token);
        self.tokens?.setRefreshToken(response.refresh_token);

        const user = await api.users.getCurrentUser().fetch();

        Sentry.setUser({
          username: user.id
        });

        self.setUserRole(api.users.roleForUser(user).role);

        self.setLoginStatus(LoginStatus.LoggedIn);

        return true;
      } catch (error) {
        console.error(error)
        self.setLoginStatus(LoginStatus.LoggedOut);
        return false;
      }
    },
    loginWithTokens: ( data: { access_token: string, refresh_token: string }) => {
      self.setLoginStatus(LoginStatus.LoggingIn);
      self.tokens?.setAccessToken(data.access_token);
      self.tokens?.setRefreshToken(data.refresh_token);
      self.setLoginStatus(LoginStatus.LoggedIn);
      return true;
    },
    logout: async () => {
      self.tokens?.setAccessToken(null);
      self.tokens?.setRefreshToken(null);

      self.resetStore();
      self.setLoginStatus(LoginStatus.LoggedOut);

      Sentry.setUser(null);

      window.location.reload();
    },
  }))

export default RootStore;
