import * as React from 'react';
import { useState, useRef, useLayoutEffect } from 'react';
import classNames from 'classnames';
import { useMount } from 'react-use';
import { AnimatePresence } from 'framer-motion';

import { ReactComponent as IconAlertInfo } from 'assets/svg/alert-info.svg';
import { ReactComponent as IconAlertSuccess } from 'assets/svg/alert-success.svg';
import { ReactComponent as IconAlertError } from 'assets/svg/alert-error.svg';
import { ReactComponent as IconClose } from 'assets/svg/close.svg';
import { store } from 'store';

export enum NotificationTypes {
  'success' = 'success',
  'error' = 'error',
  'warning' = 'warning',
  'info' = 'info',
  'dark' = 'dark',
}

export interface NotificationProps {
  type: 'success' | 'error' | 'warning' | 'info' | 'dark';
  showsCloseAction?: boolean;
  autoDismissible?: number;
  scrollTo?: boolean;
  cta?: React.ReactNode;
  identifier?: string;
  floating?: boolean;
}

const isInViewport = function (bounding: DOMRect) {
  const windowWidth = window.innerWidth || document.documentElement.clientWidth;
  const windowHeight = window.innerHeight || document.documentElement.clientHeight;

  return (
    bounding.top >= 0 &&
    bounding.left >= 0 &&
    bounding.bottom <= windowHeight &&
    bounding.right <= windowWidth
  );
};

const NotificationInner: React.FC<NotificationProps & { close(): void }> = ({
  children,
  type,
  showsCloseAction,
  close,
  scrollTo,
  cta,
  floating,
}) => {
  const element = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (element.current && scrollTo) {
      const position = element.current.getBoundingClientRect();

      if (!isInViewport(position)) {
        window.scrollBy({
          behavior: 'smooth',
          top: position.top - 32,
        });
      }
    }
  }, [scrollTo]);

  const AlertIcon = function () {
    let Icon = undefined;
    switch (type) {
      case 'success':
        Icon = IconAlertSuccess;
        break;
      case 'error':
        Icon = IconAlertError;
        break;
      case 'warning':
        Icon = IconAlertError;
        break;
      case 'info':
        Icon = IconAlertInfo;
        break;

      default:
        return null;
    }

    return (
      <div className="c-alert__col c-alert__icon">
        <Icon className="o-svg-icon" />
      </div>
    );
  };

  return (
    <div
      ref={element}
      className={classNames([
        'c-alert',
        'c-alert--small@xs',
        'c-alert--row@md',
        'c-color--invert',
        `c-alert--${type}`,
        {'c-alert--float@xs': floating ?? false},
        {'c-alert--anim': showsCloseAction ?? false},
      ])}
    >
      <AlertIcon />
      <div className="c-alert__col c-alert__body">
        {children}
      </div>
      {typeof cta !== 'undefined' && (
        <div className="c-alert__col c-alert__footer">
          {cta}
        </div>
      )}
      {showsCloseAction &&
        <div
          className="c-alert__close"
          onClick={(event) => {
            event.preventDefault();
            element.current?.classList.remove('c-alert--anim');
            element.current?.classList.add('c-alert--anim-hidden');
            close();
          }}
        >
          <IconClose className="o-svg-icon" />
        </div>
      }
    </div>
  );
};

export const Notification: React.FC<NotificationProps> = (props) => {
  const { autoDismissible } = props;
  const [isActive, setIsActive] = useState(false);

  useMount(() => {
    let timeout: NodeJS.Timeout | undefined;
    setIsActive(true);
    if (autoDismissible) {
      timeout = setTimeout(() => {
        hideNotification();
      }, autoDismissible);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  });

  function hideNotification() {
    setIsActive(false);
    if (props.identifier) {
      store.notifications.dismissNotification(props.identifier);
    }
  }

  return (
    <AnimatePresence>
      {isActive && (
        <NotificationInner
          {...props}
          close={hideNotification}
        />
      )}
    </AnimatePresence>
  );
};

export default Notification;
