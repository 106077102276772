import * as React from 'react';

// Icons
import { ReactComponent as IconMore } from 'assets/svg/more.svg';
import { ReactComponent as IconClose } from 'assets/svg/close.svg';
import { ReactComponent as IconUser } from 'assets/svg/user.svg';
import { ReactComponent as IconOut } from 'assets/svg/out.svg';
import useRequest from 'api/use-request';
import api from 'api';
import { useRef, useState } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import useClickAway from 'hooks/use-click-away';
import { store } from 'store';

interface IUserMenuProps {
}

const UserMenu: React.FunctionComponent<IUserMenuProps> = (props) => {
  let user = useRequest(api.users.getCurrentUser());
  let [isOpen, setIsOpen] = useState(false);

  const trigger = useRef<HTMLDivElement>(null);
  useClickAway(trigger, () => setIsOpen(false));

  function userInitials(first: string, last: string) {
    return first[0] + last[0];
  }

  function logout() {
    window.loader(true);
    store.logout();
  }

  function renderAvatar() {
    if (user.data) {
      return <span className="c-site-user__toggle-initial">{userInitials(user.data.first_name, user.data.last_name)}</span>
    }
  }

  if (!user.data) {
    return null;
  } else {
    return (
      <div className={classNames(['c-site-user', { 'has-popup-open': isOpen }])} ref={trigger}>
        <div className="c-site-user__toggle c-popup__pointer" onClick={() => setIsOpen(!isOpen)}>
          {renderAvatar()}
          <span className="c-site-user__toggle-more"></span>
          <IconMore className="o-svg-icon o-svg-right" />
        </div>
        <div className="c-popup">
          <div className="c-popup__header c-popup__header--lg">
            <p className="c-popup__username">{user.data.first_name} {user.data.last_name}</p>
            <div className="c-popup__toggle"><IconClose className="o-svg-icon" /></div>
          </div>
          <div className="c-popup__body">
            <ul className="c-popup__list c-popup__list--small">
              <li>
                <Link to={`/profile/general`}><IconUser className="o-svg-icon" /><span>Profile</span></Link>
              </li>
              <li className="c-popup__divider c-popup__divider--top">
                <Link to="/login" onClick={logout}><IconOut className="o-svg-icon" /><span>Log out</span></Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
};

export default UserMenu;
