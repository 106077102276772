export default {
  APP_NAME: "WPNotify 2",
  APP_VERSION: process.env.REACT_APP_VERSION || "unversioned",
  APP_ENVIRONMENT: process.env.NODE_ENV,
  APP_VERSION_CHECK_INTERVAL: process.env.REACT_APP_VERSION_CHECK_INTERVAL || "60000",
  API_BASE: process.env.REACT_APP_PUBLIC_API_BASE || "https://localhost/api/v1",
  API_CLIENT_ID: process.env.REACT_APP_API_CLIENT_ID || "2",
  API_CLIENT_SECRET: process.env.REACT_APP_API_CLIENT_SECRET || "0LfPbo6WwStG9qz3gxJCZ8QeV3se776WN3KGIQyn",
  COOKIE_ACCESS_TOKEN_KEY: process.env.REACT_APP_COOKIE_ACCESS_TOKEN_KEY || "wpnotify2_access",
  COOKIE_REFRESH_TOKEN_KEY: process.env.REACT_APP_COOKIE_REFRESH_TOKEN_KEY || "wpnotify2_refresh",
  DARKMODE_COOKIE_NAME: process.env.REACT_APP_DARKMODE_COOKIE_NAME || "darkMode",
  SENTRY_DSN: process.env.REACT_APP_SENTRY_DSN || "",
  DEBUG: process.env.REACT_APP_DEBUG || false,
  NETWORK_ERROR_TIMEOUT: process.env.REACT_APP_NETWORK_ERROR_TIMEOUT || 5000,
};
