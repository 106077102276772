import * as React from 'react';
import {
  Route,
  Switch,
  Redirect,
  useParams,
  RouteProps
} from 'react-router-dom';

import MainLayout from 'layouts/main';
import BasicLayout from 'layouts/basic';


import { useStore } from 'store';
import { Suspense } from 'react';

import NotFoundPage from 'pages/not-found';
import AccessDeniedPage from 'pages/access-denied';
import LoginPage from 'pages/login';
import UsersPage from 'pages/users';
import ForgotPasswordPage from 'pages/password-forgot';
import ResetPasswordPage from 'pages/password-reset';
import ProfilePage from 'pages/profile';
import VerifyEmailPage from 'pages/verify-email';

// import HomePage from 'pages/home';

import { AuthorizationContext, defineAbilityFor } from 'authorizations';
import useRequest from 'api/use-request';
import api from 'api';
import { AuthenticatedRoute } from 'components/routes/authenticated';
import AuthorizedRoute from 'components/routes/authorized';
import AuthRoute from 'components/routes/auth';

const loginPath = "/login";

export interface LocationState {
  from?: {
    pathname: string;
  };
}

export default function Routes() {
  const store = useStore();
  const user = useRequest(api.users.getCurrentUser(), store.isLoggedIn);

  let authorizations = defineAbilityFor(
    store.isLoggedIn? user.data : undefined
  );

  return (
    <Switch>

      <AuthRoute exact path="/login">
        <BasicLayout>
          <LoginPage />
        </BasicLayout>
      </AuthRoute>
      <Route exact path="/password/forgot">
        <BasicLayout>
          <ForgotPasswordPage />
        </BasicLayout>
      </Route>
      <Route exact path="/password/reset">
        <BasicLayout>
          <ResetPasswordPage />
        </BasicLayout>
      </Route>
      <Route exact path="/verify-email">
        <BasicLayout>
          <VerifyEmailPage />
        </BasicLayout>
      </Route>

      <AuthenticatedRoute path="/users">
        <MainLayout>
          <UsersPage />
        </MainLayout>
      </AuthenticatedRoute>

      <AuthenticatedRoute path="/">
        <AuthorizationContext.Provider value={authorizations}>
          <Switch>
            <AuthorizedRoute path="/users" subject="User" action="list">
              <MainLayout>
                <UsersPage />
              </MainLayout>
            </AuthorizedRoute>
            <Route exact path="/401">
              <MainLayout>
                <AccessDeniedPage />
              </MainLayout>
            </Route>
            <Route exact path="/403">
              <MainLayout>
                <AccessDeniedPage />
              </MainLayout>
            </Route>
            <Route exact path="/404">
              <MainLayout>
                <NotFoundPage />
              </MainLayout>
            </Route>
            <Route exact path="/">
              <Redirect to="/login" />
            </Route>
            <Route path="*">
              <MainLayout>
                <NotFoundPage />
              </MainLayout>
            </Route>
          </Switch>
        </AuthorizationContext.Provider>
      </AuthenticatedRoute>
      <Route path="*">
        <MainLayout>
          <NotFoundPage />
        </MainLayout>
      </Route>
    </Switch>
  );
}
