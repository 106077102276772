import * as React from 'react';

// Icons
import { ReactComponent as PictoUsers } from 'assets/svg/picto-users.svg';
import { ReactComponent as PictoProjects } from 'assets/svg/picto-projects.svg';
import { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import useRequest from 'api/use-request';
import api from 'api';
import useClickAway from 'hooks/use-click-away';

interface IMainNavProps {
}

const MainNav: React.FunctionComponent<IMainNavProps> = (props) => {
  const [popupOpen, setPopupOpen] = useState(false);
  const [hasMenu, setHasMenu] = useState(false);
  const user = useRequest(api.users.getCurrentUser());

  const container = useRef<HTMLDivElement>(null);
  useClickAway(container, () => setPopupOpen(false));

  useEffect(() => {
    setHasMenu(true);
  }, [user.data])

  if (!hasMenu) {
    return null;
  }

  return (
    <nav ref={container} className={classNames(['c-site-nav', { 'has-popup-open': popupOpen }])}>
      <div className="c-site-nav__header">
        <div className={classNames(['c-site-nav-toggle', 'js-offcanvas-toggle', { 'is-active': popupOpen }])}
          data-offcanvas="navigation"
          onClick={(event) => {
            event.preventDefault();
            setPopupOpen(!popupOpen);
          }}
        >
          <span className="c-site-nav-toggle__text">Menu</span>
          <div className="c-site-nav-toggle__icon">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
        <div className="c-site-logo c-site-logo--basic">
          <span className="c-site-logo__name">WPNotify</span>
        </div>
      </div>
      {hasMenu &&
        <div className="c-site-nav__popup">
          <ul className="c-site-nav__menu o-stack-2@sm">
            <li>
              <Link onClick={() => setPopupOpen(false)} to="/users">
                <span className="c-site-nav__menu-icon"><PictoUsers className="o-svg-icon" /></span>
                <span className="c-site-nav__menu-text"><span>Manage</span>Users</span>
              </Link>
            </li>
          </ul>
        </div>
      }
    </nav>
  );
};

export default MainNav;
