import HttpClient from 'api/http-client';
import * as z from 'zod';
import { GetListingRequestSchema, LinksSchema, MetaSchema } from 'api/endpoints/common';

const dateRegex = /^(?<fullyear>\d{4})-(?<month>0[1-9]|1[0-2])-(?<mday>0[1-9]|[12][0-9]|3[01])T(?<hour>[01][0-9]|2[0-3]):(?<minute>[0-5][0-9]):(?<second>[0-5][0-9]|60)(?<secfrac>\.[0-9]+)?(Z|(\+|-)(?<offset_hour>[01][0-9]|2[0-3]):(?<offset_minute>[0-5][0-9]))$/i;

export enum UserRoleTypes {
  Admin = "super-admin",
  User = "user"
}
export type UserRole = { role: UserRoleTypes, title: string, priority: number };

export const UserRoles: UserRole[] = [
  { role: UserRoleTypes.Admin, title: 'Admin', priority: 100 },
  { role: UserRoleTypes.User, title: 'User', priority: 1 },
];

export const UserObjectSchema = z.object({
  id: z.string(),
  first_name: z.string().nonempty(),
  last_name: z.string().nonempty(),
  created_at: z.string().regex(dateRegex).optional(),
  updated_at: z.string().regex(dateRegex).optional(),
  last_seen: z.string().regex(dateRegex).optional(),
  email: z.string().email(),
  role: z.string().nonempty(),
  email_verified_at: z.string().regex(dateRegex).optional(),
});

export const UserFormSchema = z.object({
  id: z.string(),
  first_name: z.string().nonempty(),
  last_name: z.string().nonempty(),
  email: z.string().email(),
  role: z.string().nonempty(),
});

export const CreateUserResponseSchema = UserObjectSchema;

export const GetUsersResponseSchema = z.object({
  data: z.array(UserObjectSchema),
  links: LinksSchema,
  meta: MetaSchema,
});

export const GetUsersRequestSchema = GetListingRequestSchema;

export const UserObjectIdSchema = z.object({
  id: z.string(),
});

export const SendWelcomeEmailRequestObjectSchema = z.object({
  user_id: z.string(),
  return_url: z.string(),
});

export type UserObject = z.infer<typeof UserObjectSchema>
export type UserFormObject = z.infer<typeof UserFormSchema>
export type GetUsersRequest = z.infer<typeof GetUsersRequestSchema>
export type GetUsersResponse = z.infer<typeof GetUsersResponseSchema>
export type UserObjectId = z.infer<typeof UserObjectIdSchema>
export type SendWelcomeEmailRequestObject = z.infer<typeof SendWelcomeEmailRequestObjectSchema>

export function getUsers(data: GetUsersRequest) {
  return new HttpClient().request<GetUsersResponse>({
    method: 'get',
    url: '/users',
    validator: GetUsersResponseSchema,
    params: data,
  });
}

export function newUser(data: Omit<UserFormObject, 'id'>) {
  return new HttpClient().request<UserObject>({
    method: 'post',
    url: '/users',
    validator: UserObjectSchema,
    data,
  });
}

export function getUser(data: UserObjectId) {
  return new HttpClient().request<UserObject>({
    method: 'get',
    url: `/users/${data.id}`,
    validator: UserObjectSchema,
  });
}

export function deleteUser(data: UserObjectId) {
  return new HttpClient().request({
    method: 'delete',
    url: `/users/${data.id}`,
    data,
  });
}

export function updateUser(data: UserFormObject, user_id: string) {
  return new HttpClient().request<UserObject>({
    method: 'put',
    url: `/users/${user_id}`,
    data,
  });
}

export function getCurrentUser() {
  return new HttpClient().request<UserObject>({
    method: 'get',
    url: `/me`,
  });
}

export function sendWelcomeEmail(data: SendWelcomeEmailRequestObject) {
  return new HttpClient().request({
    method: 'post',
    url: '/users/send-welcome-email',
    data,
  });
}

export function roleForUser(user: UserObject): UserRole {
  let matchedRole = UserRoles[2];
  UserRoles.forEach(role => {
    if (role.role === user.role) matchedRole = role;
  });
  return matchedRole;
}

export function allowedRolesForUser(user: UserObject, userBeingEdited: UserObject): UserRole[] {
  let allowedRoles: UserRole[] = [];
  let editorRole = roleForUser(user);
  let editedRole = roleForUser(userBeingEdited);

  UserRoles.forEach(role => {
    if ((editedRole.priority <= editorRole.priority && editorRole.priority > role.priority) ||
        editorRole.role == role.role) {
      allowedRoles.push(role);
    }
  });
  return allowedRoles;
}
