import * as React from 'react';

import classNames from 'classnames';

// Hooks
import { useContext, useRef, useState } from 'react';

// Icons
import { ReactComponent as IconPen } from 'assets/svg/pen.svg';
import { ReactComponent as IconMore } from 'assets/svg/more.svg';
import { ReactComponent as IconEye } from 'assets/svg/eye.svg';
import { ReactComponent as IconTrash } from 'assets/svg/trash.svg';

import { UserObject } from 'api/endpoints/users';
import { Link, useLocation } from 'react-router-dom';

import useClickAway from 'hooks/use-click-away';
import { listingContext } from './listing-context';
import * as quartzite from 'quartzite';
import DeleteConfirmationAlert, { DeleteConfirmationAlertType } from 'components/form/delete-confirmation';


interface IUserCardProps {
  user: UserObject,
  deleteHandler: (ids: string[]) => void,
  selectHandler: (ids: string[]) => void,
}

const UserCard: React.FunctionComponent<IUserCardProps> = ({ user, deleteHandler, selectHandler }) => {

  const classNameSelected = 'c-card__link-hidden';

  const [showActions, setShowActions] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const { selection } = useContext(listingContext);

  const trigger = useRef<HTMLDivElement>(null);
  useClickAway(trigger, () => setShowActions(false));

  let location = useLocation();
  let editUserLink = location.pathname + `/${user.id}/edit/general`;

  function isSelected(id: string) {
    if (selection && selection.indexOf(id) > -1) {
      return true;
    }
    return false;
  }

  function formatDate(input: string) {
    const date = new Date(input);
    return quartzite.dateString(date);
  }

  return (
    <div className="c-listing__item">
      <div ref={trigger} className={classNames(['c-card', 'c-card--link', 'c-card--bg-white', { 'is-selected': isSelected(user.id) }, { 'has-popup-open': showActions }])}>
        <div className="c-card__body">
          <div className="c-card__content">
            <p className="c-card__title"><Link to={editUserLink}>{user.first_name} {user.last_name}</Link></p>
            <div className="c-card__info">
              <p>User role: <strong>{user.role}</strong></p>
              {user.last_seen ? (
                <p>Last seen: <strong>{formatDate(user.last_seen)}</strong></p>
              ) : (
                <p>Last seen:</p>
              )
              }
            </div>
          </div>
          <div className="c-card__footer">
            <Link to={editUserLink} className="c-link-cta-light"><IconPen className="o-svg-icon" /><span>Edit</span></Link>
            <div className="c-card__actions c-card__link-hidden">
              <div onClick={(event) => setShowActions(!showActions)} className="c-card__actions-toggle">
                <IconMore className="o-svg-icon" />
              </div>
              <div className="c-popup">
                <div className="c-popup__header c-popup__header--sm">
                  <p className="c-popup__title">Actions</p>
                </div>
                <div className="c-popup__body">
                  <ul className="c-popup__list c-popup__list--small">
                    <li >
                      <Link to={editUserLink}>
                        <IconPen className="o-svg-icon" /><span>Edit User</span>
                      </Link>
                    </li>
                    <li className="c-popup__divider c-popup__divider--top">
                      <div className="c-popup__link" onClick={(event) => { event.preventDefault(); setIsDeleting(true); setShowActions(false) }}>
                        <IconTrash className="o-svg-icon" /><span>Delete User</span>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className={classNames('c-form-element', 'c-form-element--style-fill', 'c-form-element--checkbox', 'c-card__checkbox', { 'c-card__link-hidden': !isSelected(user.id) })}>
              <div className="c-form-element__field">
                <input checked={isSelected(user.id)} type="checkbox" id={`check-listing-item-${user.id}`} onChange={(event) => { selectHandler([user.id]); }} />
                <label htmlFor={`check-listing-item-${user.id}`}></label>
              </div>
            </div>
          </div>
          <DeleteConfirmationAlert
            onDelete={() => { deleteHandler([user.id]); setIsDeleting(false); }}
            onCancel={() => { setIsDeleting(false) }}
            resource_label={user.first_name + ' ' + user.last_name}
            show={isDeleting}
            type={DeleteConfirmationAlertType.Card}
          />
        </div>
      </div>
    </div>
  );
};

export default UserCard;
