import useSWR, { ConfigInterface, responseInterface } from 'swr';
import HttpClient, { HttpError } from './http-client';
import { hash } from 'utils/string';
import { useHistory } from 'react-router-dom';
import { useStore } from 'store';
import debug from 'utils/debug';
import ErrorBoundary from 'components/error-boundary';

interface Return<Data, Error>
  extends Pick<
  responseInterface<Data, Error>,
  'isValidating' | 'revalidate' | 'error'
  > {
  data: Data | undefined;
  mutate: (data?: Data, shouldRevalidate?: boolean) => Promise<Data | undefined>;
}

export type Config<Data, HttpError> = ConfigInterface<
  Data,
  HttpError
>;

export default function useRequest<T>(request: HttpClient<T>, shouldFetch: boolean = true): Return<T, HttpError> {
  const history = useHistory();
  const store = useStore();

  if (request.config && request.fetch) {
    const key = shouldFetch ? request && hash(JSON.stringify(request.config)) : null; // Null key for SWR conditional fetching

    const {
      data: response,
      error,
      isValidating,
      revalidate,
      mutate
      } = useSWR<T, HttpError>(key, request.fetch, { suspense: false });

    if (error?.message === 'Network Error') {
      store.errors.presentNetworkError('WPNotify is having difficulties connecting to the server, please make sure your internet connection is on and working.');
    }

    if (error?.response) {
      switch (error?.response.status) {
        case 401:
          history.push('/401');
          break;
        case 404:
          history.push('/404');
          break;
      }
      debug(error?.response);
    }

    return {
      data: response,
      error,
      isValidating,
      revalidate,
      mutate,
    };
  } else {
    throw new Error("Need a fully configure request param.");
  }
}
