import * as auth from './endpoints/auth';
import * as users from './endpoints/users';
import * as password from './endpoints/password';
import * as service from './endpoints/service';

export default {
  auth,
  users,
  password,
  service,
};
