import React, { createRef, ReactChild, Suspense, useEffect, useState } from "react";
import { isObject } from "utils/checks";
import { store } from 'store';

import MainNav from 'components/layout/main-nav';
import Header from 'components/layout/header';
import PageLoader from 'components/layout/page-loader';
import ErrorBoundary from 'components/error-boundary';

import UserEditModal from 'components/modals/user-edit-modal'
import Footer from 'components/layout/footer';
import Skeleton from 'react-loading-skeleton';
import useRequest from "api/use-request";
import api from "api";
import classNames from "classnames";
import { PageContextProvider } from "components/layout/page-context";
import config from "config";

interface IMainLayoutProps {
  children: ReactChild | INamedChildrenSlots,
  kioskMode?: boolean,
}

interface INamedChildrenSlots {
  content: ReactChild,
  header: ReactChild,
  footer: ReactChild,
}

export default function MainLayout(props: IMainLayoutProps) {
  const { children } = props;
  const currentUser = useRequest(api.users.getCurrentUser());
  const headerRef = createRef<HTMLElement>();
  const footerRef = createRef<HTMLElement>();

  if (!children) {
    throw new Error("Layouts need view components as children.");
  }

  // Multiple named slots used
  if (hasNamedSlots(children)) {
    const { content } = children;

    return (
      <PageContextProvider headerRef={headerRef} footerRef={footerRef}>
        <div className={classNames(['c-body'])}>
          <MainNav />
          <Header ref={headerRef} />
          <ErrorBoundary>
            {content ? content : null}
          </ErrorBoundary>
        </div>
      </PageContextProvider>
    );
  }

  // Single component
  return (
    <PageContextProvider headerRef={headerRef} footerRef={footerRef}>
      <div className={classNames(['c-body'])}>
        <ErrorBoundary>
          <Suspense fallback={<HeaderSkeleton />}>
            <MainNav />
            <Header ref={headerRef} />
          </Suspense>
          <Suspense fallback={<PageLoader />}>
              {children}
          </Suspense>
        </ErrorBoundary>
        <Footer ref={footerRef} />
      </div>
    </PageContextProvider>
  );

};

const hasNamedSlots = (children: any): children is INamedChildrenSlots => isObject(children) && 'content' in children;


export interface IHeaderSkeletonProps {
}

export function HeaderSkeleton(props: IHeaderSkeletonProps) {
  return (
    <header id="site-header" className="c-site-header">
      <div className="o-container-fluid o-container--full">
        <div className="c-site-header__wrapper">
          <div className="c-site-header__main">
            <div className="c-site-header__headline">
              <Skeleton />
            </div>
          </div>
          <div className="c-site-header__utility">
            <div className="c-site-user">
              <Skeleton />
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}
